import React, { useEffect, useRef, useState } from "react";
import Binance from "../../../assets/images/Binance_black.png";
import BayBit from "../../../assets/images/Bybit_black.png";
import { useSelector, useDispatch } from "react-redux";
import useApi from "../../../helpers/apiHelper/requestHelper";
import {
  setLoading,
  setResponseLoading,
  setShowConnectionSuccessModal,
  setShowConnectionFailModal,
  setConnectionFailMessage,
  setBinanceConnecting,
} from "../../../redux/loading/loadingSlice";
import { toastify } from "../../../helpers/toast/toastify";
import { updateUser } from "../../../redux/users/usersSlice";
import ExchangeConnects from "./ExchangeConnects";
import copyIcon from "../../../assets/images/Copy.svg";
import { missingPopup } from "../../../helpers/dataHelper/missingData";
import RenderLinks from "../../../components/links/RenderLinks";
import AuthLoader from "../../../components/loader/authLoader";
import DeleteMember from "../../../components/modals/DeleteMember";
import { setCurrentTab } from "../../../redux/links/linksSlice";
import UpdateAndVerifyAccountOTP from "../accountSettings/UpdateAndVerifyAccountOTP";

function Connection({ setTab }) {
  const user = useSelector((value) => value?.user?.user);
  const [showConnection, setShowConnection] = useState(true);
  const [view, setView] = useState("connect");
  const [callSubmit, setCallSubmit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [credentials, setCredentials] = useState({
    exchange: "",
    apiKey: "",
    secretKey: "",
  });
  const [showOtpPopUp, setShowOtpPopUp] = useState({
    show: false,
    buttonClick: "",
  });
  const [exchangeName, setExchangeName] = useState("");
  const [selectedConnectionType, setSelectedConnectionType] = useState("");
  const [showBinanceConnection, setShowBinanceConnection] = useState(false);
  const [showBybitConnection, setShowBybitConnection] = useState(false);
  const [differentExchange, setDifferentExchange] = useState(false);
  const dispatch = useDispatch();
  const api = useApi();
  const loaders = useSelector((state) => state?.loader);
  const inputRef = useRef(null);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setScroll(false);
  }, [scroll]);

  useEffect(() => {
    if (
      user?.memberCommunities[0]?.community?.leader?.binanceCredentials
        ?.isConnected
    ) {
      setShowBinanceConnection(true);
    }
    if (
      user?.memberCommunities[0]?.community?.leader?.bybitCredentials
        ?.isConnected
    ) {
      setShowBybitConnection(true);
    }
  }, [user]);

  const liveUrl = true;
  const oauthBinance = async () => {
    if (
      user?.binanceCredentials?.apiKey &&
      user?.binanceCredentials?.secretKey
    ) {
      getUserCredentials("Binance", "callSubmit");
    } else {
      let userAgent = navigator?.userAgent;
      if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
        dispatch(setLoading(false));
      } else {
        dispatch(setLoading(true));
      }
      dispatch(setBinanceConnecting(true));
      const clientId = "39vZ9un1Fd";
      const redirect_uri = liveUrl
        ? "https%3A%2F%2Fmy.copyyy.it%2Fsignin"
        : "https%3A%2F%2Fdev.copyyy.it%2Fsignin";
      const scope = "user:openId,create:apikey";
      const cUrl = `https://accounts.binance.com/en/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirect_uri}&scope=${scope}`;
      window.location.href = cUrl;
    }
  };

  const oauth = async () => {
    if (user?.bybitCredentials?.apiKey && user?.bybitCredentials?.secretKey) {
      getUserCredentials("ByBit", "callSubmit");
    } else {
      let userAgent = navigator?.userAgent;
      if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
        dispatch(setLoading(false));
      } else {
        dispatch(setLoading(true));
      }
      const clientId = "3ccf41551815af4";
      const cUrl = `https://www.bybit.com/oauth?client_id=${clientId}&response_type=code&scope=openapi&state=123abc`;
      window.location.href = cUrl;
    }
  };

  const getUserCredentials = async (event, hint) => {
    let exchangeKey = "";
    if (event === "Binance") {
      exchangeKey = "binance";
    } else if (event === "ByBit") {
      exchangeKey = "bybit";
    }

    if (
      (event === "ByBit" && !user?.bybitCredentials?.apiKey) ||
      (event === "Binance" && !user?.binanceCredentials?.apiKey)
    ) {
      setCredentials((prev) => ({
        ...prev,
        apiKey: "",
        secretKey: "",
        exchange: event,
      }));
    } else {
      const updatedCredentials = {
        ...credentials,
        exchange: event,
      };
      setCredentials(updatedCredentials);

      dispatch(setLoading(true));
      const response = await api(
        "get",
        `binanceCredentials/index/${exchangeKey}`
      );
      dispatch(setLoading(false));

      if (response?.status) {
        let data = response?.data;
        if (data?.length === 3) {
          setCredentials({
            apiKey: data?.apiKey,
            secretKey: data?.secretKey,
            exchange: data?.exchange,
          });
          if (hint === "callSubmit") {
            setCallSubmit(true);
          }
        } else {
          setCredentials({
            apiKey: data?.apiKey,
            secretKey: data?.secretKey,
            exchange: data?.exchange,
          });
          if (hint === "callSubmit") {
            setCallSubmit(true);
          }
        }
      } else {
        setCredentials({
          apiKey: "",
          secretKey: "",
          exchange: event,
        });
      }
    }
  };

  useEffect(() => {
    if (callSubmit) {
      submit();
    }
  }, [callSubmit]);

  const switchToApiKeyView = () => {
    setScroll(true);
    setSelectedConnectionType("");
    setShowConnection((prevShowConnection) => !prevShowConnection);
    setView((prevView) => (prevView === "connect" ? "apiKeys" : "connect"));
    setCredentials({
      apiKey: "",
      secretKey: "",
      exchange: "",
    });
  };

  const noAction = () => {
    setShowModal(false);
  };

  const submit = async () => {
    if (!credentials?.exchange) missingPopup("Exchange");
    else if (!credentials?.apiKey) missingPopup("API Key");
    else if (!credentials?.secretKey) missingPopup("Secret Key");
    else {
      dispatch(setResponseLoading(true));
      const response = await api("post", "binanceCredentials/create", {
        ...credentials,
      });

      if (response?.status) {
        dispatch(setResponseLoading(false));
        dispatch(updateUser(response?.data));
        dispatch(setShowConnectionSuccessModal(true));
        if (user?.firstVisit) {
          setTimeout(async () => {
            if (user?.role === "member") {
              dispatch(setCurrentTab("subscribe"));
              await api("post", "users/update", {
                onboardingStep: "subscribe",
              });
            }
            if (user?.role === "leader") {
              dispatch(setCurrentTab("payment"));
              await api("post", "users/update", {
                onboardingStep: "payment",
              });
            }
          }, 2000);
        }

        if (!callSubmit) {
          switchToApiKeyView();
        } else {
          setCallSubmit(false);
        }
      } else {
        setCallSubmit(false);
        dispatch(setResponseLoading(false));
        // toastify(response?.message);
        dispatch(setConnectionFailMessage(response?.message));
        dispatch(setShowConnectionFailModal(true));
      }
    }
  };

  const disconnect = (exchange) => {
    setShowModal(true);
    setExchangeName(exchange);
  };

  const yesAction = async () => {
    setShowModal(false);
    dispatch(setLoading(true));

    const isConnected =
      exchangeName === "Binance"
        ? user?.binanceCredentials?.isConnected
        : exchangeName === "ByBit"
        ? user?.bybitCredentials?.isConnected
        : false;

    if (isConnected) {
      const credentialToUpdate =
        exchangeName === "Binance"
          ? {
              binanceCredentials: {
                ...user?.binanceCredentials,
                isConnected: false,
                isConnectedSpot: false,
                isConnectedUsdFuture: false,
              },
              onboarding: {
                ...user.onboarding,
                connect: false,
              },
            }
          : exchangeName === "ByBit"
          ? {
              bybitCredentials: {
                ...user?.bybitCredentials,
                isConnected: false,
                isConnectedSpot: false,
                isConnectedDerivatives: false,
              },
              onboarding: {
                ...user.onboarding,
                connect: false,
              },
            }
          : null;

      if (credentialToUpdate) {
        const response = await api("post", "users/update", credentialToUpdate);

        if (response?.status) {
          dispatch(updateUser(response?.data?.user));
          toastify("Exchange disconnected successfully.", "success");
        } else {
          toastify(response?.message);
        }
      }
    } else {
      toastify("No credentials found to disconnect.", "error");
    }

    dispatch(setLoading(false));
  };

  const differentExchangeYesAction = () => {
    setDifferentExchange(false);
  };

  const handleExchangeChange = (selectedValue) => {
    setSelectedConnectionType(selectedValue?.value);
    setCredentials((prev) => ({
      ...prev,
      exchange: selectedValue.value,
    }));
    getUserCredentials(selectedValue.value);
    const tempInput = document.createElement("input");
    document.body.appendChild(tempInput);
    tempInput.className = "hidden-input";
    tempInput.focus();

    setTimeout(() => {
      tempInput?.remove();
      if (inputRef.current) {
        inputRef?.current?.focus();
      }
    }, 0);
  };
  const handleErrorModal = () => {
    dispatch(setShowConnectionFailModal(false));
    dispatch(setConnectionFailMessage(""));
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const myResponse = await api("get", "users/index");
      if (myResponse) {
        dispatch(updateUser(myResponse?.data?.user));
      }
    };
    fetchUserData();
  }, []);

  const callApi = async (buttonClick) => {
    if (showOtpPopUp?.buttonClick === "ByBit" || buttonClick === "ByBit") {
      if (showBybitConnection && user?.binanceCredentials?.isConnected) {
        setDifferentExchange(true);
      } else {
        user?.bybitCredentials?.isConnected ? disconnect("ByBit") : oauth();
      }
    } else if (showOtpPopUp?.buttonClick === "Binance") {
      if (showBinanceConnection && user?.bybitCredentials?.isConnected) {
        setDifferentExchange(true);
      } else {
        user?.binanceCredentials?.isConnected
          ? disconnect("Binance")
          : oauthBinance();
      }
    } else {
      submit();
    }
  };

  return (
    <React.Fragment>
      {showConnection && view === "connect" && (
        <div className="connection pb-sm-50px">
          {loaders?.awaitingResponse ? (
            <AuthLoader component="connection" />
          ) : loaders?.showConnectionSuccessModal ? (
            <AuthLoader component="connectionsuccess" />
          ) : loaders?.showConnectionFailModal &&
            loaders?.connectionFailMessage ? (
            <AuthLoader
              component="connectionfail"
              handleErrorModal={handleErrorModal}
              connectionFailMessage={loaders?.connectionFailMessage}
            />
          ) : (
            <>
              {user?.firstVisit && (
                <div className="text-center">
                  {user?.memberCommunities[0]?.community?.name ? (
                    <p className="fs16 bold mb-21 text-center">
                      {user?.memberCommunities[0]?.community?.name?.toUpperCase()}
                    </p>
                  ) : (
                    <span className="mb-27 text-light">.</span>
                  )}
                </div>
              )}
              <div className="title">
                {user?.binanceCredentials?.isConnected ? (
                  <h3>Connected Trading Account</h3>
                ) : (
                  <h3>Connect Your Trading Account</h3>
                )}

                <p>
                  You will automatically copy trade using the trading account
                  you connect with. When connecting you’ll accept to receive buy
                  and sell signals to that particular account. Nothing else.
                </p>
              </div>
              <div className="connectionBlock">
                <div
                  className={`accountCard ${
                    user?.binanceCredentials?.isConnected ? "active" : ""
                  }`}
                >
                  <div className="account">
                    <img src={Binance} alt="binance" />
                    <div className="d-flex align-items-center">
                      <p>API Status:</p>
                      <p>
                        <span
                          className={
                            user?.binanceCredentials?.isConnectedUsdFuture
                              ? "cyan"
                              : "red"
                          }
                        ></span>{" "}
                        Futures
                      </p>
                      <p>
                        <span
                          className={
                            user?.binanceCredentials?.isConnectedSpot
                              ? "cyan"
                              : "red"
                          }
                        ></span>{" "}
                        Spot
                      </p>
                    </div>
                  </div>
                  <button
                    className={`${
                      showBinanceConnection &&
                      user?.bybitCredentials?.isConnected
                        ? "cursorNotAllowed"
                        : ""
                    }`}
                    onClick={() => {
                      if (user.firstVisit) {
                        callApi("Binance");
                      } else {
                        setShowOtpPopUp({ show: true, buttonClick: "Binance" });
                      }
                    }}
                  >
                    {user?.binanceCredentials?.isConnected
                      ? "Disconnect"
                      : "Connect"}
                  </button>
                </div>
                <div
                  className={`accountCard ${
                    user?.bybitCredentials?.isConnected ? "active" : ""
                  }`}
                >
                  <div className="account">
                    <img src={BayBit} alt="ByBit" />
                    <div className="d-flex align-items-center">
                      <p>API Status:</p>
                      <p>
                        <span
                          className={
                            user?.bybitCredentials?.isConnectedDerivatives
                              ? "cyan"
                              : "red"
                          }
                        ></span>{" "}
                        Futures
                      </p>
                      <p>
                        <span
                          className={
                            user?.bybitCredentials?.isConnectedSpot
                              ? "cyan"
                              : "red"
                          }
                        ></span>{" "}
                        Spot
                      </p>
                    </div>
                  </div>
                  <button
                    className={`${
                      showBybitConnection &&
                      user?.binanceCredentials?.isConnected
                        ? "cursorNotAllowed"
                        : ""
                    }`}
                    onClick={() => {
                      if (user.firstVisit) {
                        callApi("ByBit");
                      } else {
                        setShowOtpPopUp({ show: true, buttonClick: "ByBit" });
                      }
                    }}
                  >
                    {user?.bybitCredentials?.isConnected
                      ? "Disconnect"
                      : "Connect"}
                  </button>
                </div>
                <div className="apiInsted">
                  {!user?.binanceCredentials?.isConnected &&
                    !user?.bybitCredentials?.isConnected && (
                      <h3 onClick={switchToApiKeyView}>
                        &gt; Use API keys instead
                      </h3>
                    )}
                </div>
              </div>
            </>
          )}
        </div>
      )}
      {!showConnection && view === "apiKeys" && (
        <div className="connection pb-sm-50px">
          {loaders?.awaitingResponse ? (
            <AuthLoader component="connection" />
          ) : loaders?.showConnectionSuccessModal ? (
            <AuthLoader component="connectionsuccess" />
          ) : loaders?.showConnectionFailModal ? (
            <AuthLoader
              component="connectionfail"
              handleErrorModal={handleErrorModal}
              connectionFailMessage={loaders?.connectionFailMessage}
            />
          ) : (
            <>
              {user?.firstVisit && (
                <div className="text-center">
                  {user?.memberCommunities[0]?.community?.name ? (
                    <p className="fs16 bold mb-21 text-center">
                      {user?.memberCommunities[0]?.community?.name?.toUpperCase()}
                    </p>
                  ) : (
                    <span className="mb-27 text-light">.</span>
                  )}
                </div>
              )}
              <div className="title">
                <h3>Connect Your Trading Account</h3>
                <p>
                  You will automatically copy trade using the trading account
                  you connect with. When connecting you’ll accept to receive buy
                  and sell signals to that particular account. Nothing else.
                </p>
              </div>
              <div className="connectionBlock">
                <div className="title mt-5 mb-3">
                  <h3>Select your exchange(s) to connect</h3>
                </div>
                <ExchangeConnects
                  onExchangeChange={handleExchangeChange}
                  credentials={credentials}
                />
                {selectedConnectionType && (
                  <>
                    <div className="messageInput">
                      <div className="form-floating">
                        <input
                          ref={inputRef}
                          type="text"
                          className="form-control"
                          id="apiKeys"
                          placeholder="API Key"
                          value={credentials?.apiKey}
                          onChange={(e) =>
                            setCredentials((prev) => ({
                              ...prev,
                              apiKey: e.target.value,
                            }))
                          }
                        />
                        <label for="apiKeys">Api Key</label>
                      </div>
                    </div>
                    <div className="messageInput">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="secretKey"
                          placeholder="Secret Key"
                          value={credentials?.secretKey}
                          onChange={(e) =>
                            setCredentials((prev) => ({
                              ...prev,
                              secretKey: e.target.value,
                            }))
                          }
                        />
                        <label for="secretKey">Secret Key</label>
                      </div>
                    </div>
                    {credentials?.exchange !== "ByBit" && (
                      <>
                        <p className="mt-4">
                          <strong>Important:</strong> your exchange requires you
                          to use our IP address for the API setup.{" "}
                        </p>
                        <div className="url p-0">
                          <div className="position-relative d-flex align-items-center justify-content-end copyBlock mt-2">
                            <input
                              type="text"
                              className="form-control fs14 regular"
                              value="108.129.27.187"
                              disabled
                            />
                            <span className="position-absolute">
                              <img
                                className="cursor-pointer"
                                onClick={() => {
                                  const communityIPAddress = "108.129.27.187";

                                  navigator.clipboard
                                    .writeText(communityIPAddress)
                                    .then(() => {
                                      toastify(
                                        "IP Address copied to clipboard!",
                                        "success"
                                      );
                                    })
                                    .catch((error) => {
                                      console.error(
                                        "Clipboard write error:",
                                        error
                                      );
                                      toastify(
                                        "Failed to copy community link to clipboard.",
                                        "error"
                                      );
                                    });
                                }}
                                src={copyIcon}
                              />
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
                <div className="apiInsted">
                  <h3 onClick={switchToApiKeyView}>&gt; Use Quick connect</h3>
                  {credentials.apiKey && credentials.secretKey && (
                    <button
                      className="btn cyan-btn fs13"
                      onClick={() => {
                        if (user.firstVisit) {
                          callApi();
                        } else {
                          setShowOtpPopUp({ show: true });
                        }
                      }}
                    >
                      {user?.firstVisit ? "Next" : "Save Changes"}
                    </button>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}
      {showModal && (
        <DeleteMember
          noAction={noAction}
          yesAction={yesAction}
          text="Are you sure you want to Disconnect?"
          subtext="If you do, you won’t be receiving further buy/sell signals and you’re responsible for any active trades."
        />
      )}
      {differentExchange && (
        <DeleteMember
          yesAction={differentExchangeYesAction}
          text="You need to connect again"
          subtext="We've switched exchange. Please connect and make sure you have the proper funds on the exchange. Your copy trade won't work until you reconnect."
          noneclass="d-none"
        />
      )}
      {showOtpPopUp?.show && (
        <UpdateAndVerifyAccountOTP
          setShowOtpPopUp={setShowOtpPopUp}
          callApi={callApi}
        />
      )}
      <RenderLinks page="copyTradeConnect" />
    </React.Fragment>
  );
}

export default Connection;
